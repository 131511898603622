<template>
  <div class="memorabilia-cont">
    <van-image class="pic_margin" width="100vw" src="/banner_polymerization.jpg" />
    <van-row class="pic-next-cont itembk">
      <div class="top-cont">
        <van-notice-bar left-icon="diamond-o" color="#2A79FF" background="#ffffff">规划行程，一键下单，方便快捷。</van-notice-bar>
      </div>
      <!-- 列表 -->
      <van-steps direction="vertical" inactive-color="#929292" :active="-1">
        <!-- 列表条目 -->
        <van-step class="node">
          <template #inactive-icon>
            <!-- <van-icon class="traffic-icon iconfont" color="#fff" class-prefix="icon" name="huochepiaomianxing" /> -->
            <!-- <van-icon class="traffic-icon iconfont" color="#fff" class-prefix="icon" name="jiudian" /> -->
            <van-icon
              class="planebg traffic-icon iconfont"
              color="#fff"
              class-prefix="icon"
              name="tuijianjipiao"
            />
          </template>
          <div class="time">
            <!-- <div class="MMDD">10-30</div> -->
            <div class="hhmm list-text">17:30</div>
          </div>
          <div class="contain">
            <!-- <van-row type="flex" justify="space-between" class="year">
            <span class="list-title">2020-11-2</span>-->
            <van-row type="flex" justify="space-between">
              <div>
                <van-row type="flex" justify="space-between">
                  <span class="list-text-big">CZ438（天津->杭州）</span>
                  <span class="list-text-big red">￥500</span>
                </van-row>
                <div class="footer">出行人：大头、小头</div>
              </div>
              <van-col>
                <van-icon class="del-icon" name="delete" @click="del" />
              </van-col>
            </van-row>
          </div>
        </van-step>

        <van-step class="node">
          <van-button plain size="mini" type="info" @click="showModel=true">添加行程（ 机、火、酒、聚合 ）</van-button>
          <div class="gap"></div>
        </van-step>
      </van-steps>
    </van-row>

    <Question :config.sync="config" ref="quest" />

    {{config}}
    <div class="gap" style="height:60px"></div>
    <van-submit-bar :price="300050" button-text="一键预定" />
    <!-- <van-submit-bar :price="300050" button-text="一键预定" @submit="" /> -->

    <van-dialog
      v-model="showModel"
      title="添加行程"
      :closeOnClickOverlay="true"
      :showConfirmButton="false"
    >
      <span style="padding:0 1rem">随行人员：</span>
      <van-field name="checkboxGroup" label>
        <template #input>
          <van-checkbox-group v-model="checkboxGroup" direction="horizontal">
            <van-checkbox name="1" shape="square" style="padding-bottom:6px">人员一</van-checkbox>
            <van-checkbox name="2" shape="square" style="padding-bottom:6px">人员二</van-checkbox>
            <van-checkbox name="3" shape="square" style="padding-bottom:6px">人员三</van-checkbox>
            <van-checkbox name="4" shape="square" style="padding-bottom:6px">人员四</van-checkbox>
            <van-checkbox name="5" shape="square" style="padding-bottom:6px">人员五</van-checkbox>
          </van-checkbox-group>
        </template>
      </van-field>
      <van-grid :column-num="4">
        <van-grid-item
          icon-prefix="icon"
          class="iconfont blue"
          icon="guojijipiao"
          to="/buyAircraft"
          text="飞机"
        />
        <van-grid-item
          icon-prefix="icon"
          class="iconfont green"
          icon="guojihuochepiao"
          to="/buyTrain"
          text="火车"
        />
        <van-grid-item
          icon-prefix="icon"
          class="iconfont warning"
          icon="guojijiudian"
          to="/buyHotel"
          text="酒店"
        />
        <van-grid-item
          icon-prefix="icon"
          class="iconfont violet"
          icon="jinnang"
          text="智能"
          @click="showModel=false;$refs.quest.open()"
        />
      </van-grid>
    </van-dialog>
  </div>
</template>

<script>
import Question from "./components/Question";
import { recommend } from "@/services/polymerization";
export default {
  components: { Question },
  mounted() {
    window.setTitle("聚合订票");
  },
  data() {
    return {
      showModel: false, // 添加行程开关
      checkboxGroup: ["1", "2", "3", "4", "5"],
      showQuest: true, // 问题开关
      config: [], // 只能规划的配置
    };
  },
  methods: {
    fetchTrip(){
      recommend(this.config)
    },
    del() {
      this.$dialog
        .confirm({
          title: "提示",
          closeOnClickOverlay: true,
          message: "是否要删除该行程？",
        })
        .then(() => {
          // on confirm
        })
        .catch(() => {
          // on cancel
        });
    },
  },
  watch: {
    config() {
      this.fetchTrip()
    },
  },
};
</script>

<style lang="less" scoped>
@blue: #1989fa;
@orange: #ff611c;
@green: #04bb5b;
.del-icon {
  font-size: 1.3rem;
  color: red;
  padding: 3px 3px 0.8rem 1rem;
}
.traffic-icon {
  border-radius: 50%;
  transform: translateY(2px);
  display: inline-block;
  background-color: #fff;
}
.planebg {
  padding: 4px;
  background-color: @blue;
}
.hotelbg {
  padding: 4px;
  background-color: @orange;
}
.trainbg {
  padding: 4px;
  background-color: @green;
}
.top-cont {
  padding: 0.5rem 0 0;
  text-align: right;
}
.year {
  background-color: #e2f0fd;
  border-radius: 4px;
  justify-content: center;
  .list-title {
    color: @blue;
  }
}
.contain {
  min-height: 2rem;
  // padding-bottom: 1rem;
}
.list-title,
.list-text {
  display: inline-block;
}
.list-title {
  font-weight: 600;
  padding-top: 0.6rem;
}
.time {
  position: absolute;
  top: 0.6rem;
  right: calc(100% + 2rem);
  width: 60px;
  text-align: right;
  .YYYY {
    color: @blue;
    font-size: 1rem;
    margin-bottom: 1rem;
  }
  .MMDD {
    font-size: 0.8rem;
    font-weight: 600;
  }
  .hhmm {
    font-size: 10px;
    font-weight: 300;
  }
}
.footer {
  font-size: 10px;
  font-weight: 400;
}
.memorabilia-cont {
  background-color: rgba(0, 0, 0, 0);
}
</style>

<style lang="less">
.memorabilia-cont {
  .van-step__icon--active {
    font-size: 1.3rem;
    background-color: #fff;
  }
  .van-steps--vertical {
    padding-left: 5rem;
    padding-right: 1rem;
  }
  .van-icon-diamond-o {
    padding-right: 0.6rem;
  }
}
</style>

