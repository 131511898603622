<template>
  <div>
    <!-- 主体 -->
    <van-dialog v-model="show" title="智能规划" :show-confirm-button="false">
      <van-form ref="editInfo">
        <van-field
          v-model="config[active].from"
          name="出发地"
          label="出发地"
          placeholder="请输入出发城市"
          :rules="[{ required: true, message: '请填写出发地' }]"
        />
        <van-field
          v-model="config[active].to"
          name="目的地"
          label="目的地"
          placeholder="请输入目的|学校|商业区"
          :rules="[{ required: true, message: '请填写地' }]"
        />
        <van-field name="checkboxGroup" label="出行方式">
          <template #input>
            <van-checkbox-group v-model="config[active].vehicle" direction="horizontal">
              <van-checkbox name="aircraft" shape="square">飞机</van-checkbox>
              <van-checkbox name="train" shape="square">火车</van-checkbox>
            </van-checkbox-group>
          </template>
        </van-field>
        <van-field
          readonly
          clickable
          name="datetimePicker"
          :value="config[active].time"
          :label="active==0?'出发日期':'离开日期'"
          placeholder="点击选择时间"
          @click="showPicker = true"
          :rules="[{ required: true, message: '请选择时间' }]"
        />
      </van-form>
      <div class="poly-btn-group">
        <van-button plain @click="show=false;resetConfig()">关闭</van-button>
        <van-button plain @click="active--" v-if="active>0">返回</van-button>
        <van-button plain @click="next">添加</van-button>
        <van-button plain type="info" @click="finish">完成</van-button>
      </div>
    </van-dialog>

    <!-- 选日期 -->
    <van-popup v-model="showPicker" position="bottom">
      <van-datetime-picker
        type="datetime"
        @confirm="onConfirmTime"
        @cancel="showPicker = false"
        :min-date="active>0?new Date(config[active-1].time):new Date()"
      />
    </van-popup>
  </div>
</template>

<script>
import { searchPosition } from "@/services/hotel";
import { AddDateFull, DiffDate } from "@/services/date";
import { TravelFrom } from "@/common/config";

export default {
  data() {
    return {
      active: 0, // 走到第几步了。总抓手
      show: true, // 对话框
      showPicker: false, // 日期选择器
      config: [
        {
          time: AddDateFull(new Date()),
          from: TravelFrom,
          to: "上海交大",
          vehicle: ["aircraft", "train"],
        },
      ], // 配置，用户填的信息都装在这里
    };
  },
  methods: {
    // 打开，父组件调用
    open() {
      this.resetConfig();
      this.show = true;
    },
    // 选择时间
    onConfirmTime(time) {
      this.config[this.active].time = AddDateFull();
      this.showPicker = false;
    },
    // 重置配置
    resetConfig() {
      this.active = 0;
      this.config = [
        { time: "", from: "", to: "", vehicle: ["aircraft", "train"] },
      ];
    },
    // 下一步 单击下一步会传个event进来没啥用。单击完成会传个回调过来
    next(cb = () => {}) {
      this.$refs.editInfo.validate().then(() => {
        // 调用搜索目的地方法（关键字，关键字，回调）
        searchPosition(
          this.config[this.active].from,
          "",
          (fromPosition, fromResult) => {
            this.config[this.active].fromMap = fromResult;
            searchPosition(
              this.config[this.active].to,
              "",
              (toPosition, toResult) => {
                this.config[this.active].toMap = toResult;
                if (typeof cb == "function") return cb(); // 如果是完成执行回调并结束
                this.config.push({
                  // 否则追加下一个结点
                  time: AddDateFull(this.config[this.active].time, 3),
                  from: toResult.city,
                  to: "",
                  vehicle: ["aircraft", "train"],
                });
                this.active++; // 激活下一页}
              }
            );
          }
        );
      });
    },
    // 完成
    finish() {
      this.$refs.editInfo.validate().then(() => {
        this.show = false;
        this.next(() => {
          this.$emit("update:config", this.config.splice(0, this.active + 1));
          this.resetConfig();
        });
      });
    },
  },
};
</script>

<style lang="less">
.poly-btn-group {
  margin-top: 1rem;
  width: 100%;
  display: flex;
  .van-button--normal {
    width: 100%;
    border: 0;
  }
}
</style>
<style lang="less" scoped>
</style>
