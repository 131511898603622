import { init } from "@/services/list";
import { checkCity } from "@/services/aircraft.js";
import { DiffDate } from "@/services/date.js";
import store from '@/store'

const laji = ["0", "10", "3", "2"]  // 这些座位老师不坐

let vehicleList = init()

export function recommend(config) {
    for (let i = 0; i < config.length; i++) {
        const element = config[i];
        fetchVehicle(config[i]) // 获取车票+机票
    }
}

/**
 * 生成最佳出行方案
 * 注！update方法中执行到await会先返回...等阻塞函数跑完了他又回去直接执行了...坑的要死
 * 因此在update函数中 return Promise，利用Promise的then来确保异步逻辑
 * @param {*} config 一段配置
 */
function fetchVehicle(config) {
    config.fromMap.city = config.fromMap.city.replace(/市/g, "") // 去掉干扰文字
    config.toMap.city = config.toMap.city.replace(/市/g, "")
    // 选了车票拉车票
    if (config.vehicle.includes('train'))
        vehicleList.update("train", {
            fromCityName: config.fromMap.city,
            toCityName: config.toMap.city,
            fromDate: config.time.split(' ')[0],
            corp: null,
        }).then(() => {
            // 选了机票拉圾票
            if (config.vehicle.includes('aircraft'))
                vehicleList.update("aircraft", {
                    depCityCode: checkCity(config.fromMap.city).code,
                    arrCityCode: checkCity(config.toMap.city).code,
                    depDate: config.time.split(' ')[0],
                    corp: null
                }).then(() => {
                    // console.log(vehicleList.list)
                    recommendVehicle(vehicleList.list, config) // 这里有机票+车票
                })
        });
}

function recommendVehicle(list, config) {
    let peoList = store.getters.personList
    let trainSeat = [] // 最低合规火车座位
    let aircraftSeat = 9999 // 最低合规飞机座位
    peoList.forEach(element => {
        let trainArr = element.stand.trainSeatInfo_seatInfo.split(";")
        if (0 in trainSeat) {
            trainSeat = trainArr.filter(val => trainSeat.indexOf(val) > -1)
        } else {
            trainSeat = trainArr
        }
        aircraftSeat = Math.min(aircraftSeat, element.stand.allowBookHighest)
    });
    trainSeat = trainSeat.filter(e => !laji.includes(e))  // 过滤低端座位，无座

    // 过滤掉“超标坐席”和“票不多”的坐席
    list = list.filter(element => {
        // 如果是火车
        if (element.hasOwnProperty('displaySeat')) {
            let result = element.displaySeat.filter(e => parseInt(e.seatNum) > peoList.length + 2 && trainSeat.includes(e.seatClass.toString()))
            return result.length > 0
        }
        // 如果是飞机
        if (element.hasOwnProperty('flightSeat')) {
            let result = element.flightSeat.filter(e => parseInt(e.number) > 7 && e.flightSeat <= aircraftSeat)
            return result.length > 0
        }
    })

    // 按出发时间排序，最接近的就是最好的喽
    list.sort((a, b) => {
        let aTime = `${a.trainBeginDate || a.departDate} ${a.startTime || a.departTime}`
        let bTime = `${b.trainBeginDate || b.departDate} ${b.startTime || b.departTime}`
        let difa = DiffDate(config.time, aTime, 'minute', 'YYYY-MM-DD HH:mm:ss')
        let difb = DiffDate(config.time, bTime, 'minute', 'YYYY-MM-DD HH:mm:ss')
        return difa - difb
    })

    let best = list[0] || {} // 最好的座位在这里
    let index = 0 // 最高端的座位下标
    if (best.hasOwnProperty('displaySeat')) {
        for (let j = 0; j < best.displaySeat.length; j++) {
            const element = best.displaySeat[j];
            if (trainSeat.includes(element.seatClass)) index = j
        }
    }
    if (best.hasOwnProperty('flightSeat')) {
        let temp = ''
        for (let j = 0; j < best.flightSeat.length; j++) {
            const element = best.flightSeat[j];
            if (element.seatGrade <= aircraftSeat && temp != element.seatClass) {
                index = j
                temp = element.seatClass
            }
        }
    }

    console.log({ index, best })
    return { index, best }
}

{
    checkInDate: "2020-12-01"
    checkOutDate: "2020-12-02"
    city: "保定"
    corp: "行旅"
    currentIndex: 1
    key: ""
    latitude: 38.879989
    locationId: ""
    locationName: ""
    longitude: 115.47146
    pageSize: 100
    radius: 8000
    rangePrice: "0-99999"
    sort: "DA"
    star: 0
} 